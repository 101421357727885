<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <title-text
                        :title="PROVIDER.EducationalFulfillmentPage.Title"
                        class="qa-educational-fulfillment-title"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <recess-card variant="variant1">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="w-20 mr-4">
                                <recess-multi-select
                                    :selected-options="selectedFilters.examStatus"
                                    :options="examStatusOptions"
                                    :single-select="true"
                                    :select-options-text="examStatusOptions && examStatusOptions[0].displayText"
                                    class="qa-course-exam-status"
                                    @input="(newValue) => setSpecificFilter('examStatus', newValue)"
                                />
                            </div>

                            <div class="w-20 mr-4">
                                <recess-multi-select
                                    :selected-options="selectedFilters.courseCompletedStatus"
                                    :options="courseCompletedStatusOptions"
                                    :single-select="true"
                                    :select-options-text="courseCompletedStatusOptions && courseCompletedStatusOptions[0].displayText"
                                    class="qa-course-completion-status"
                                    @input=" (newValue) => setSpecificFilter('courseCompletedStatus', newValue)"
                                />
                            </div>

                            <div class="w-30 mr-4">
                                <recess-search-input
                                    v-model.trim="selectedFilters.queryByStartmomentId"
                                    :placeholder="PROVIDER.EducationalFulfillmentPage.FilterInput.SearchByStartmomentId.Placeholder"
                                    class="qa-course-search-startmomentId" 
                                    @input="(newValue)=>setSpecificFilter('queryByStartmomentId', newValue)"
                                />
                            </div>

                            <div class="w-50">
                                <recess-search-input
                                    v-model.trim="selectedFilters.queryByName"
                                    :placeholder="PROVIDER.EducationalFulfillmentPage.FilterInput.SearchByName.Placeholder"
                                    class="qa-course-search-name" 
                                    @input="(newValue)=>setSpecificFilter('queryByName', newValue)"
                                />
                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>

            <educational-fulfillment-list :set-selected-filters="localFilters" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import getExamStatusOptionsMixin from '@/mixins/getExamStatusOptionsMixin' 
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

const TitleText = () => import('@/components/atoms/TitleText')
const EducationalFulfillmentList = () => import('@/components/organisms/Fulfillment/EducationalFulfillmentList')

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'EducationalFulfillmentOverview',
    components: {
        TitleText,
        EducationalFulfillmentList
    },
    mixins: [getExamStatusOptionsMixin, filterMixin],
    data() {
        return {
            PROVIDER,
            examStatusOptions: [{
                value: '',
                displayText: PROVIDER.EducationalFulfillmentPage.FilterInput.CourseExamStatusAllExamOption.Placeholder
            }],
            courseCompletedStatusOptions: [
                {
                    value: '',
                    displayText: PROVIDER.EducationalFulfillmentPage.FilterInput.CourseCompletedStatus.StatusAllExamOption.SelectText
                },
                {
                    value: 'true',
                    displayText: PROVIDER.EducationalFulfillmentPage.FilterInput.CourseCompletedStatus.StatusCompletedOption.SelectText
                },
                {
                    value: 'false',
                    displayText: PROVIDER.EducationalFulfillmentPage.FilterInput.CourseCompletedStatus.StatusNotCompletedOption.SelectText
                }
            ],
            selectedFilters: {
                examStatus: '',
                courseCompletedStatus: '',
                queryByStartmomentId: '',
                queryByName: '',
                providerId: localStorage.getItem('providerId')
            }
        }
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    },
    watch: {
        selectedProvider: {
            handler() {
                if (
                    this.selectedProvider &&
                    this.selectedFilters &&
                    this.selectedProvider.id !== this.selectedFilters.providerId
                ) {

                    this.setSpecificFilter('providerId', this.selectedProvider.id)
                }
            },
            deep: true
        }
    }, 
    mounted() {
        this.$nextTick(() => {
            this.getExamStatusOptions()
        })
    }
}
</script>
