 
import { getEnumList } from "@/../../shared/api/SharedClient"

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

const getExamStatusOptionsMixin = {
    methods: {
        async getExamStatusOptions() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_FULFILLMENT_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'examStatus',
                    null,
                    null,
                    false
                )
                if (!response) return
                this.examStatusOptions = [
                    ...this.examStatusOptions,
                    ...response
                ]
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving exam status enum list',
                    error
                )
            }
        }
    }
}

export default getExamStatusOptionsMixin
